import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form, Field, CccisdSelect } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import adminCenterQuery from './adminCenterQuery.graphql';
import DashboardTable from './Table/index.js';
import AddDeployment from './AddDeployment/index.js';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const actingRole = Fortress.user.acting.role.handle;
const actingGroupId = Fortress.user.acting.group?.id;
const isUber = actingRole === 'uberadmin';

const AdminCenter = () => {
    const [groupingUnitId, setGroupingUnitId] = useState(null);
    const [selectOptions, setSelectOptions] = useState(null);
    const [assignmentList, setAssignmentList] = useState(null);
    const [loading, setLoading] = useState(true);
    const deploymentTable = useRef();

    useEffect(() => {
        initializeFilters();
    }, []);

    useEffect(() => {
        setLoading(true);
    }, []);

    const initializeFilters = async () => {
        const response = await client.query({
            query: adminCenterQuery,
            fetchPolicy: 'network-only',
            variables: {},
        });

        const guList = response.data.groups.groupingUnitList.map(item => {
            return {
                value: item.group.groupId,
                label: item.group.label,
                sites: item.childGroups.siteList,
            };
        });

        setSelectOptions({ guList });

        setAssignmentList(response.data.flows.assignmentList);

        const guId = isUber ? +guList[0]?.value : actingGroupId;
        setGroupingUnitId(guId);

        setLoading(false);
    };

    const getSites = guId => {
        if (guId) {
            const match = selectOptions.guList.find(item => item.value === guId);
            return match?.sites;
        }
    };

    // const getOrgName = () => {
    //     if (groupingUnitId) {
    //         const match = selectOptions.guList.find(item => item.value === groupingUnitId);
    //         return match?.label;
    //     }
    // };

    const renderFilters = () => {
        if (!isUber) {
            return;
        }
        return (
            <>
                {selectOptions && groupingUnitId && (
                    <Formik
                        initialValues={{
                            organization: groupingUnitId,
                        }}
                        render={({ setFieldValue }) => {
                            return (
                                <Form>
                                    <div className={style.filterBar}>
                                        <Field
                                            name="organization"
                                            component={CccisdSelect}
                                            label="Organization"
                                            options={selectOptions.guList}
                                            onChange={e => {
                                                setFieldValue('organization', +e.target.value);
                                                setGroupingUnitId(+e.target.value);
                                                // setSites(+e.target.sites);
                                            }}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    />
                )}
            </>
        );
    };

    const loadData = () => {
        deploymentTable.current.loadData();
    };

    if (loading) {
        return <Loader loading />;
    }

    return (
        <div>
            {renderFilters()}
            <div>
                {groupingUnitId &&
                    (Fortress.user.acting.role_data.adminCenter ||
                        ['uberadmin', 'guAdmin'].includes(Fortress.user.acting.role.handle)) && (
                        <AddDeployment
                            groupingUnitId={groupingUnitId}
                            sites={getSites(groupingUnitId)}
                            assignmentList={assignmentList}
                            loadData={loadData}
                        />
                    )}
                <DashboardTable
                    ref={deploymentTable}
                    groupingUnitId={groupingUnitId}
                    selectOptions={selectOptions}
                    isUber={isUber}
                />
            </div>
        </div>
    );
};

export default AdminCenter;
